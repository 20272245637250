/* jslint browser: true */

import Immutable from 'seamless-immutable';
import { User } from '../../../apis/allevi-api-wrapper';

export interface UserState extends User {
  readonly authenticated: boolean;
}

const initialState = Immutable<UserState>({
  authenticated: false,
  _id: '',
  email: '',
  name: {
    first: '',
    last: ''
  },
  structure: {
    default: {
      team: '',
      organization: ''
    },
    home: {
      team: '',
      organization: ''
    }
  },
  access: '',
  //@ts-expect-error
  tier: undefined
});

export default (state = initialState, action: any) => {
  switch (action.type) {
    // case 'UPDATE_USER':
    //   return {
    //     ...state,
    //     email: action.user.email,
    //     token: action.user.token,
    //   };
    case 'AUTHENTICATE_USER_SUCCESS':
      return state.merge({
        authenticated: true,
        _id: action.user._id,
        email: action.user.email,
        name: action.user.name,
        structure: action.user.structure,
        access: action.user.access,
        tier: action.user.tier
      });

    case 'AUTHENTICATE_USER_FAILURE':
    case 'GET_USER_FAILURE':
      return initialState;

    case 'GET_USER_SUCCESS':
      // Intercom re-init with user info
      if (window.hostEnvironment === 'CLOUD' && window.Intercom && window.intercomSettings) {
        if (action.user.intercomUserHash && action.user.intercomUserHash.length > 0) {
          window.Intercom('boot', {
            app_id: window.intercomSettings.app_id,
            name: `${action.user.name.first} ${action.user.name.last}`,
            email: action.user.email,
            user_hash: action.user.intercomUserHash
          });
        } else {
          window.Intercom('boot', {
            app_id: window.intercomSettings.app_id,
            name: `${action.user.name.first} ${action.user.name.last}`
          });
        }
      }

      return state.merge({
        authenticated: true,
        _id: action.user._id,
        email: action.user.email,
        name: action.user.name,
        structure: action.user.structure,
        access: action.user.access,
        tier: action.user.tier
      });

    default:
      return state;
  }
};
